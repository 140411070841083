/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AlertMessage from 'components/AlertMessage';
import Modal from 'components/Modal';
import Steps from 'components/Steps';
import { PAYMENT_METHOD_OPTIONS, STEPS_STATUS } from 'constants/common';
import {
  CONTACTS_FORM_KEYS,
  FIRST_STEP_ALL_KEYS,
  LINE_ITEMS_FORM_KEYS,
  STEPS_COUNT,
} from 'constants/order-form';
import { ORDER_ALERT_MESSAGES, ORDER_STEPS } from 'constants/orders';
import { API_ROUTES } from 'constants/routes';
import { DEFAULT_TIMEZONE } from 'constants/timezone';
import dayjs from 'dayjs';
import { useRequest } from 'hooks/useRequest';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentCustomerState,
  currentOrderSaveCard,
  currentOrderTaxRate,
  currentUserState,
} from 'state/atoms';
import { AVEntityOrderFormField, AVOrderFormField } from 'types/av-form-fields';
import {
  AVOrder,
  CancelSetupIntentPayload,
  CreateAVOrder,
  CreateSetupIntentPayload,
  EndClient,
  GoogleTimeZone,
  ProgramManagerData,
  State,
  UpdatedCreateAVOrder,
} from 'types/av-orders';
import { InputTypes } from 'types/form';
import { AvailableBundles, AvailableItems } from 'types/item-bundle';
import {
  filterItemsAndBundles,
  generateAVOrderFormValidation,
  getAVOrderDefaultValues,
  getSectionWiseFormFields,
  isRushOrder,
} from 'utils/avUtils';
import { AV_ORDER_STATUS } from 'utils/common.enum';
import { convertObjIntoQueryParam } from 'utils/helper';
import { createOrderValidationScheme } from 'validations/order-form.schema';

import { yupResolver } from '@hookform/resolvers/yup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, SetupIntent, Stripe } from '@stripe/stripe-js';

import FirstStep from './OrderFormSteps/FirstStep';
import SecondStep from './OrderFormSteps/SecondStep';
import ThirdStep, { IThirdStepRef } from './OrderFormSteps/ThirdStep';
import OrderModalFooter from './Footer';
import OrderSubmissionSuccess from './OrderSubmissionSuccess';

interface IOrderForm {
  states: State[];
  nextOrderId: string;
  orderModal: boolean;
  orderToCopy?: AVOrder;
  keysToCopy?: string[];
  endClients: EndClient[];
  items: AvailableItems[];
  bundles: AvailableBundles[];
  editOrder: AVOrder | undefined;
  resetForm: () => void;
  closeOrderModal: () => void;
  addToLocalOrderState: (order: AVOrder) => void;
  updateLocalOrderState: (order: AVOrder) => void;
  getAVItemsAndBundles: (clientId: string) => Promise<void>;
  isWithinCancellationPolicy: boolean;
}

const OrderForm: React.FC<IOrderForm> = ({
  items,
  states,
  bundles,
  editOrder,
  endClients,
  keysToCopy,
  nextOrderId,
  orderToCopy,
  orderModal,
  resetForm,
  closeOrderModal,
  getAVItemsAndBundles,
  addToLocalOrderState,
  updateLocalOrderState,
  isWithinCancellationPolicy,
}) => {
  const stripePromise = useRef<Stripe | null>(null);
  const [stripeElementsOptions, setStripeElementsOptions] = useState({});
  const [setupIntentClientSecret, setSetupIntentClientSecret] =
    useState<string>('');
  const [setupIntentId, setSetupIntentId] = useState<string>('');
  const saveCard = useRecoilValue(currentOrderSaveCard);
  const setSaveCard = useSetRecoilState(currentOrderSaveCard);

  const thirdStepRef = useRef<IThirdStepRef>(null);

  const currentUser = useRecoilValue(currentUserState);
  const customer = useRecoilValue(currentCustomerState);
  const setCurrentOrderTaxRate = useSetRecoilState(currentOrderTaxRate);
  const { get, put, post, isLoading } = useRequest();

  const [stripeLoading, setStripeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS_COUNT.FIRST);

  const [googleTimezone, setGoogleTimezone] = useState<GoogleTimeZone | null>(
    null,
  );

  const [formSteps, setFormSteps] = useState<typeof ORDER_STEPS>(
    JSON.parse(JSON.stringify(ORDER_STEPS)),
  );

  const [showSubmittedData, setShowSubmittedData] = useState(false);
  const [latesSubmittedOrder, setLatesSubmittedOrder] = useState<AVOrder>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCardErrorAlert, setShowCardErrorAlert] = useState(false);
  const [showCardErrorAlertMessage, setShowCardErrorAlertMessage] =
    useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showOnHoldAlert, setShowOnHoldAlert] = useState(false);
  const [showRushOrderAlert, setShowRushOrderAlert] = useState(false);
  const [avEntityOrdersFormFields, setAVEntityOrdersFormFields] = useState<
    AVEntityOrderFormField[]
  >([]);
  const [avOrdersFormFields, setAVOrdersFormFields] = useState<
    AVOrderFormField[]
  >([]);
  const [programManagerData, setProgramManagerData] =
    useState<ProgramManagerData>();

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    trigger,
    getFieldState,
    getValues,
    setError,
    formState: { errors, dirtyFields },
  } = useForm<CreateAVOrder>({
    defaultValues: getAVOrderDefaultValues(
      nextOrderId,
      avOrdersFormFields,
      currentUser,
      bundles,
      items,
      editOrder,
    ),
    resolver: yupResolver<CreateAVOrder>(
      createOrderValidationScheme.concat(
        generateAVOrderFormValidation(
          avOrdersFormFields,
          programManagerData?.enableProgramManager,
        ),
      ),
    ),
  });

  useEffect(() => {
    const formItems = getValues('lineItems.items');
    const formBundles = getValues('lineItems.bundles');
    const formValues = getValues();

    if (!formItems?.length || !formBundles?.length || editOrder) {
      const defaults = getAVOrderDefaultValues(
        nextOrderId,
        avOrdersFormFields,
        currentUser,
        bundles,
        items,
        editOrder,
      );
      reset({ ...formValues, lineItems: defaults.lineItems });
    } else if (orderToCopy && keysToCopy?.includes('lineItems')) {
      const defaults = getAVOrderDefaultValues(
        nextOrderId,
        avOrdersFormFields,
        currentUser,
        bundles,
        items,
        orderToCopy,
      );
      reset({ ...formValues, lineItems: defaults.lineItems });
    }
  }, [
    avOrdersFormFields,
    bundles,
    currentUser,
    editOrder,
    getValues,
    items,
    nextOrderId,
    reset,
    orderToCopy,
    keysToCopy,
  ]);

  useEffect(() => {
    avOrdersFormFields.forEach((field) => {
      if (field.inputType === InputTypes.DATE) {
        let dateValue;
        if (editOrder?.metadata?.[field.metadataKey]) {
          dateValue = dayjs(editOrder?.metadata?.[field.metadataKey])
            .set('hours', 0)
            .set('minute', 0)
            .format('YYYY-MM-DD');
        } else {
          dateValue = undefined;
        }

        setValue(
          `metadata.${field.metadataKey}` as keyof CreateAVOrder,
          dateValue,
        );

        return;
      }

      if (!editOrder?.metadata || !field.metadataKey) {
        return;
      }
      setValue(
        `metadata.${field.metadataKey}` as keyof CreateAVOrder,
        editOrder?.metadata[field.metadataKey],
      );
    });
  }, [
    avOrdersFormFields,
    currentUser?.timezone,
    editOrder?.metadata,
    setValue,
  ]);

  const venueAddress = watch('venue.address');
  const state = watch('venue.state');
  const city = watch('venue.city');
  const zipCode = watch('venue.zip');
  const endClient = watch('endClient');

  const getTimezoneByCityState = useCallback(
    async (city: string, state: string) => {
      const timestamp = dayjs().toDate().getTime();
      const selectedState = states.find((s) => s._id === state);

      const query = {
        city,
        state: selectedState?.name,
        country: selectedState?.country,
        timestamp,
      };
      const urlQuery = convertObjIntoQueryParam(query);
      const { data } = await get<GoogleTimeZone>(
        `${API_ROUTES.GET_TIMEZONE}?${urlQuery}`,
      );
      if (data?.id) {
        setValue('localTimeZone', data?.id, { shouldDirty: true });
        setGoogleTimezone(data);
      }
    },
    [get, setValue, states],
  );

  useEffect(() => {
    if (state && city) {
      getTimezoneByCityState(city, state);
    }
  }, [state, city, getTimezoneByCityState]);

  useEffect(() => {
    if (keysToCopy?.length && orderToCopy) {
      if (keysToCopy.includes('eventInfo')) {
        setValue('endClient', orderToCopy.endClient?._id);
        setValue('attendeeCount', orderToCopy.attendeeCount);
        setValue('enteredBy', orderToCopy.enteredBy._id);
      }
      if (keysToCopy.includes('venueInfo') && orderToCopy.venue) {
        setValue('venue.name', orderToCopy.venue.name);
        setValue('venue.address', orderToCopy.venue.address);
        setValue('venue.city', orderToCopy.venue.city);
        setValue('venue.zip', orderToCopy.venue.zip);

        const venueStateId = orderToCopy.venue.state; // getting ID here
        setValue('venue.state', venueStateId);
      }
      if (keysToCopy.includes('lineItems')) {
        const { filteredItems, filteredBundles } = filterItemsAndBundles(
          orderToCopy.lineItems,
        );
        setValue('endClient', orderToCopy.endClient?._id);
        setValue('lineItems.items', filteredItems);
        setValue('lineItems.bundles', filteredBundles);
      }
    }
  }, [orderToCopy, orderToCopy?._id, setValue, keysToCopy, keysToCopy?.length]);

  const getFormFields = useCallback(
    async (clientId: string) => {
      const { data } = await get<AVEntityOrderFormField[]>(
        `${API_ROUTES.AV_CUSTOMER}/client-order-form-fields/${clientId}`,
      );

      if (data) {
        setAVEntityOrdersFormFields(data);
        setAVOrdersFormFields(data.map(({ formField }) => formField));
      }
    },
    [get],
  );

  const getProgramManagersFromEndClient = useCallback(
    async (endClientId: string | null) => {
      if (!endClientId) {
        setProgramManagerData(undefined);
        return;
      }

      const { data } = await get<ProgramManagerData>(
        `${API_ROUTES.AV_CUSTOMER}/program-managers/${endClientId}`,
      );

      if (data) {
        setProgramManagerData(data);
      }
    },
    [],
  );

  const getClientData = useCallback(async () => {
    if (endClient) {
      setLoading(true);
      await getAVItemsAndBundles(endClient);
      await getFormFields(endClient);
      await getProgramManagersFromEndClient(endClient);
      setLoading(false);
    }
  }, [
    endClient,
    getAVItemsAndBundles,
    getFormFields,
    getProgramManagersFromEndClient,
  ]);

  useEffect(() => {
    if (currentUser?.customer) {
      setValue('customer', currentUser.customer, { shouldDirty: false });
    }
    if (currentUser?.stripePublicKey) {
      loadStripe(currentUser.stripePublicKey).then((stripe) => {
        stripePromise.current = stripe;
      });
    }
  }, [currentUser, setValue]);

  useEffect(() => {
    getClientData();
  }, [getClientData]);

  const onCloseOrderModal = () => {
    setShowConfirmationModal(true);
  };

  const createSetupIntent = useCallback(
    async (stripeCustomerId: string | undefined) => {
      if (!stripeCustomerId) {
        return;
      }
      const res = await post<SetupIntent, CreateSetupIntentPayload>(
        API_ROUTES.CREATE_SETUP_INTENT,
        { customerId: stripeCustomerId },
      );
      const { data } = res;

      if (!data) {
        return;
      }

      if (!data.client_secret) {
        return;
      }
      setSetupIntentId(data.id);
      setSetupIntentClientSecret(data.client_secret);
      setStripeElementsOptions({
        clientSecret: data.client_secret,
      });
    },
    [post],
  );

  const cancelSetupIntent = useCallback(
    async (setupIntentId: string | undefined) => {
      if (!setupIntentId) return;

      const res = await post<SetupIntent, CancelSetupIntentPayload>(
        API_ROUTES.CANCEL_SETUP_INTENT,
        { setupIntentId },
      );

      if (!res.error) {
        setSetupIntentId('');
        setSetupIntentClientSecret('');
        setStripeElementsOptions({});
      }
    },
    [post],
  );

  const closeConfirmationModal = useCallback(async () => {
    if (setupIntentId) {
      await cancelSetupIntent(setupIntentId);
    }
    closeOrderModal();
    resetForm();
    setShowConfirmationModal(false);
  }, [closeOrderModal, resetForm, setupIntentId, cancelSetupIntent]);

  const getTaxAmount = useCallback(async () => {
    const payload = {
      zip: zipCode,
      state: states.find((s) => s._id === state)?.abbrev,
      date: new Date(),
      customerCode: currentUser?.customer,
    };
    const { data, error } = await post(API_ROUTES.GET_TAX_RATE, payload);
    if (error) return null;
    setCurrentOrderTaxRate(data as number);
    return data;
  }, [
    zipCode,
    currentUser?.customer,
    state,
    states,
    post,
    setCurrentOrderTaxRate,
  ]);

  const goToFirstStep = useCallback(() => {
    const { FIRST, SECOND, THIRD } = STEPS_COUNT;
    setCurrentStep(FIRST);
    setFormSteps((prev) => {
      prev[SECOND]!.status = STEPS_STATUS.UPCOMING;
      if (prev[THIRD]) {
        prev[THIRD]!.status = STEPS_STATUS.UPCOMING;
      }
      if (prev[FIRST]) {
        prev[FIRST]!.status = STEPS_STATUS.CURRENT;
      }
      return prev;
    });
  }, []);

  const proceedToSecondStep = useCallback(async () => {
    const { FIRST, SECOND, THIRD } = STEPS_COUNT;

    if (currentStep === FIRST) {
      const taxRate = await getTaxAmount();
      if (!taxRate) {
        setError('venue.address', {
          type: 'required',
          message: 'Invalid Address',
        });
        return;
      }
    }

    setCurrentStep(SECOND);
    setFormSteps((prev) => {
      prev[FIRST]!.status = STEPS_STATUS.COMPLETE;
      if (prev[SECOND]) {
        prev[SECOND]!.status = STEPS_STATUS.CURRENT;
      }
      if (prev[THIRD]) {
        prev[THIRD]!.status = STEPS_STATUS.UPCOMING;
      }
      return prev;
    });
  }, [currentStep, getTaxAmount, setError]);

  const goToSecondStep = useCallback(
    async (checkRushOrderCutOff = true) => {
      const formValues = getValues();
      const dynamicFormKeys = avOrdersFormFields
        .filter(({ metadataKey }) => metadataKey)
        .map(({ metadataKey }) => `metadata.${metadataKey}`);

      const formFirstStepKeys = [
        ...FIRST_STEP_ALL_KEYS,
        ...dynamicFormKeys,
        ...CONTACTS_FORM_KEYS,
      ];

      // TODO: Temporary hack. Fix by venue.state always being an object { _id, name }
      const idx = formFirstStepKeys.indexOf('venue.state.name');
      if (idx !== -1) {
        formFirstStepKeys.splice(idx, 1);
      }
      formFirstStepKeys.push('venue.state');

      const shouldGoNext = await trigger(
        formFirstStepKeys as unknown as keyof CreateAVOrder,
      );

      let isAddressValid = false;
      const state = states.find((s) => s._id === formValues.venue?.state);
      if (venueAddress && state?.country !== 'Puerto Rico') {
        try {
          const response = await post<
            { isValid: boolean; subpremise: string },
            { address: string }
          >(`${API_ROUTES.VALIDATE_ADDRESS}`, {
            address: `${formValues.venue?.address ?? ''} ${
              formValues.venue?.city ?? ''
            } ${state?.name ?? ''} ${formValues.venue?.zip ?? ''} ${
              state?.country ?? ''
            }`,
          });
          if (response?.data) {
            isAddressValid = response.data.isValid;
          }
        } catch (error) {
          console.error('Error:', error);
        }
      } else {
        isAddressValid = true;
      }

      if (isAddressValid === false) {
        setShowErrorAlert(true);
        setError('venue.address', {
          type: 'required',
          message: 'Invalid Address',
        });
        return;
      }
      if (!shouldGoNext) {
        setShowErrorAlert(true);
        return;
      }

      const { enableProgramManager } = programManagerData ?? {};
      const { programManager } = formValues;
      const isProgramManagerRequired = enableProgramManager && !programManager;

      if (isProgramManagerRequired) {
        setError('programManager', {
          type: 'required',
          message: 'This Field is required',
        });

        setShowErrorAlert(true);
        return;
      }

      if (checkRushOrderCutOff && customer && formValues?.startTime) {
        const { rushFee } = customer;

        const startTime = dayjs(formValues?.startTime).format('HH:mm');

        const isStartDateChanged =
          editOrder?.startDate !==
          dayjs(formValues?.startDate).format('YYYY-MM-DD');

        const isStartTimeChanged = editOrder?.startTime !== startTime;
        const isOrderTimeChanged = isStartDateChanged || isStartTimeChanged;

        if (
          (!editOrder || isOrderTimeChanged) &&
          isRushOrder(
            customer,
            formValues?.startDate,
            formValues?.startTime,
            formValues?.localTimeZone ?? DEFAULT_TIMEZONE,
          )
        ) {
          setValue('rushFee', rushFee, { shouldDirty: false });
          setShowRushOrderAlert(true);
          return;
        } else if (editOrder?.orderStatus === AV_ORDER_STATUS.ON_HOLD) {
          setValue('rushFee', 0, { shouldDirty: false });
        } else {
          setValue('rushFee', editOrder?.rushFee ?? 0, { shouldDirty: false });
        }
      }

      proceedToSecondStep();
    },
    [
      getValues,
      avOrdersFormFields,
      trigger,
      venueAddress,
      programManagerData,
      customer,
      proceedToSecondStep,
      states,
      post,
      setError,
      editOrder,
      setValue,
    ],
  );

  const goToThirdStep = useCallback(async () => {
    const shouldGoNext = await trigger(
      LINE_ITEMS_FORM_KEYS as unknown as keyof CreateAVOrder,
    );

    if (!shouldGoNext) {
      setShowErrorAlert(true);
      return;
    }

    const selectedEndClient = endClients.find(
      (client) => client._id === endClient,
    );
    if (
      (!editOrder || editOrder.orderStatus === AV_ORDER_STATUS.ON_HOLD) &&
      setupIntentId === ''
    ) {
      await createSetupIntent(selectedEndClient?.stripeId);
    }

    const { SECOND, THIRD } = STEPS_COUNT;
    setCurrentStep(THIRD);
    setFormSteps((prev) => {
      prev[SECOND]!.status = STEPS_STATUS.COMPLETE;
      if (prev[THIRD]) {
        prev[THIRD]!.status = STEPS_STATUS.CURRENT;
      }
      return prev;
    });
  }, [
    trigger,
    endClients,
    editOrder,
    setupIntentId,
    endClient,
    createSetupIntent,
  ]);

  const addOrder = useCallback(
    async (payload: UpdatedCreateAVOrder) => {
      const { data } = await post<AVOrder, UpdatedCreateAVOrder>(
        `${API_ROUTES.AV_CUSTOMER}/client-order`,
        payload,
      );

      if (data) {
        addToLocalOrderState(data);
        setLatesSubmittedOrder(data);
        setShowSubmittedData(true);
      }
    },
    [addToLocalOrderState, post],
  );

  const updateOrder = useCallback(
    async (payload: UpdatedCreateAVOrder, orderId: string) => {
      const modifiedBundles: unknown[] = [];
      const modifiedItems: unknown[] = [];
      if (dirtyFields?.lineItems) {
        dirtyFields?.lineItems.bundles?.forEach((bundle, idx) => {
          if (bundle) {
            const bundleId = getValues(`lineItems.bundles.${idx}.bundle`);
            modifiedBundles.push({
              ...bundle,
              bundle: bundleId,
            });
          }
        });
        dirtyFields?.lineItems.items?.forEach((item, idx) => {
          if (item) {
            const itemId = getValues(`lineItems.items.${idx}.item`);
            modifiedItems.push({
              ...item,
              item: itemId,
            });
          }
        });
      }
      const { data } = await put<AVOrder, UpdatedCreateAVOrder>(
        `${API_ROUTES.AV_CUSTOMER}/client-order/${orderId}`,
        {
          ...payload,
          modifiedFields: {
            ...dirtyFields,
            lineItems: {
              bundles: modifiedBundles,
              items: modifiedItems,
            },
          },
        },
      );

      if (data) {
        setValue('orderId', data.orderId);
        updateLocalOrderState(data);
        setShowSubmittedData(true);
      }
    },
    [dirtyFields, put, getValues, setValue, updateLocalOrderState],
  );

  const apiPayloadCreator = useCallback(
    ({
      orderId,
      enteredBy,
      attendeeCount,
      customer,
      endClient,
      lineItems,
      startDate,
      startTime,
      setupTime,
      endDate,
      endTime,
      onSiteContact,
      speakerName,
      venue,
      localTimeZone,
      stripeSetupIntentId,
      comments,
      rushFee,
      programManager,
      type,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      modifiedFields: __,
      metadata,
    }: CreateAVOrder) => {
      let newLineItems = [
        ...(lineItems?.bundles || []),
        ...(lineItems?.items || []),
      ];

      if (newLineItems?.length) {
        newLineItems = newLineItems.filter((item) => item?.quantity);
      }
      const uniqueLineItems = newLineItems.filter(
        (lineItem, index, self) =>
          index ===
          self.findIndex(
            (t) => t.bundle === lineItem.bundle && t.item === lineItem.item,
          ),
      );
      const orderStatus = showOnHoldAlert
        ? AV_ORDER_STATUS.ON_HOLD
        : AV_ORDER_STATUS.SUBMITTED;
      const isOnHold = orderStatus === AV_ORDER_STATUS.ON_HOLD;
      setValue('orderStatus', orderStatus);

      const apiPayload = {
        orderId,
        type,
        enteredBy: currentUser?._id ?? enteredBy,
        attendeeCount: attendeeCount || 0,
        startDate:
          !startDate && isOnHold ? '' : dayjs(startDate).format('YYYY-MM-DD'),
        startTime:
          !startTime && isOnHold ? '' : dayjs(startTime).format('HH:mm'),
        endDate:
          !endDate && isOnHold ? '' : dayjs(endDate).format('YYYY-MM-DD'),
        endTime: !endTime && isOnHold ? '' : dayjs(endTime).format('HH:mm'),
        setupTime:
          !setupTime && isOnHold ? '' : dayjs(setupTime).format('HH:mm'),
        customer,
        endClient,
        lineItems: uniqueLineItems,
        programManager,
        onSiteContact,
        speakerName,
        venue,
        localTimeZone: !localTimeZone && isOnHold ? '' : localTimeZone,
        metadata,
        comments,
        orderStatus,
        stripeSetupIntentId,
        rushFee,
        saveCard,
      };
      return apiPayload;
    },
    [currentUser?._id, saveCard, setValue, showOnHoldAlert],
  );

  const onSubmit: SubmitHandler<CreateAVOrder> = useCallback(
    async (data) => {
      const apiPayload = apiPayloadCreator(data);

      if (!programManagerData?.enableProgramManager) {
        apiPayload.programManager = null;
      }

      const previousOrderStatus = editOrder?.orderStatus;
      const selectedEndClient = endClients.find(
        (client) => client._id === data.endClient,
      );

      if (
        !showOnHoldAlert &&
        selectedEndClient?.paymentMethod ===
          PAYMENT_METHOD_OPTIONS.CREDIT_CARD &&
        (!editOrder ||
          (editOrder?._id && previousOrderStatus === AV_ORDER_STATUS.ON_HOLD))
      ) {
        setStripeLoading(true);
        const response = await thirdStepRef.current?.confirmSetupIntent();
        if (!response) {
          setShowCardErrorAlertMessage(
            ORDER_ALERT_MESSAGES.CARD_DETAILS_REQUIRED,
          );
          setShowCardErrorAlert(true);
          setStripeLoading(false);
          return;
        }
        setStripeLoading(false);

        if (response.setupIntent) {
          apiPayload.stripeSetupIntentId = response.setupIntent.id;
        } else {
          if (response.error?.code) {
            let errorMessage =
              response.error.message || ORDER_ALERT_MESSAGES.CARD_DECLINED;
            errorMessage +=
              '\n\nAlternatively, you can submit your order without billing information.';
            errorMessage += `\n\nError Code: ${response.error.code}\n`;
            setShowCardErrorAlertMessage(errorMessage);
            setShowCardErrorAlert(true);
          }
          return;
        }
      } else if (setupIntentId && setupIntentClientSecret) {
        await cancelSetupIntent(setupIntentId);
      }

      if (editOrder?._id) {
        await updateOrder(apiPayload, editOrder?._id);
        return;
      }

      await addOrder(apiPayload);
      setSaveCard(false);

      if (showOnHoldAlert) {
        if (!data.stripeSetupIntentId && setupIntentId) {
          await cancelSetupIntent(setupIntentId);
        }
        setShowOnHoldAlert(false);
      }
    },
    [
      apiPayloadCreator,
      programManagerData?.enableProgramManager,
      editOrder,
      endClients,
      showOnHoldAlert,
      setupIntentId,
      setupIntentClientSecret,
      addOrder,
      setSaveCard,
      cancelSetupIntent,
      updateOrder,
    ],
  );

  const holdOrder = async () => {
    let shouldPutOnHold = await trigger('endClient');
    if (editOrder?.startDate) {
      shouldPutOnHold = await trigger('startDate');
    }
    setShowOnHoldAlert(false);
    if (shouldPutOnHold) {
      onSubmit(getValues());
    }
  };

  const { contactFields, eventDetailFields, eventProfileFields } =
    useMemo(() => {
      return getSectionWiseFormFields(avEntityOrdersFormFields);
    }, [avEntityOrdersFormFields]);

  const hideOnHoldButton = useMemo(() => {
    return editOrder?._id && editOrder?.orderStatus !== AV_ORDER_STATUS.ON_HOLD;
  }, [editOrder?._id, editOrder?.orderStatus]);

  const renderStep = () => {
    switch (currentStep) {
      case STEPS_COUNT.FIRST:
        return (
          <>
            <div className='flex h-[calc(100%-80px)] gap-4'>
              <FirstStep
                watch={watch}
                setValue={setValue}
                states={states}
                control={control}
                isLoading={loading}
                editOrder={editOrder}
                keysToCopy={keysToCopy}
                endClients={endClients}
                googleTimezone={googleTimezone}
                contactDynamicFields={contactFields}
                avOrdersFormFields={avOrdersFormFields}
                eventDetailFormFields={eventDetailFields}
                eventProfileDynamicFields={eventProfileFields}
                programManagerData={programManagerData}
                isWithinCancellationPolicy={isWithinCancellationPolicy}
              />
            </div>

            <OrderModalFooter
              disabledNextButton={loading}
              goNext={goToSecondStep}
              onClose={onCloseOrderModal}
              onHold={
                hideOnHoldButton ? undefined : () => setShowOnHoldAlert(true)
              }
            />
          </>
        );

      case STEPS_COUNT.SECOND:
        return (
          <>
            <div className='flex h-[calc(100%-80px)] gap-4'>
              <SecondStep
                errors={errors}
                items={items}
                bundles={bundles}
                control={control}
                setValue={setValue}
                watch={watch}
              />
            </div>

            <OrderModalFooter
              goBack={goToFirstStep}
              goNext={goToThirdStep}
              onClose={onCloseOrderModal}
              onHold={
                hideOnHoldButton ? undefined : () => setShowOnHoldAlert(true)
              }
            />
          </>
        );

      case STEPS_COUNT.THIRD:
        return (
          <>
            <div className='flex h-[calc(100%-80px)] gap-4'>
              <Elements
                stripe={stripePromise.current}
                options={stripeElementsOptions}
              >
                <ThirdStep
                  ref={thirdStepRef}
                  setupIntentId={setupIntentId}
                  endClients={endClients}
                  programManagers={programManagerData?.programManagers ?? []}
                  eventProfileDynamicFields={eventProfileFields}
                  contactDynamicFields={contactFields}
                  eventDetailFormFields={eventDetailFields}
                  bundles={bundles}
                  items={items}
                  states={states}
                  getValues={getValues}
                  goToFirstStep={goToFirstStep}
                  goToSecondStep={() => goToSecondStep(false)}
                  editOrder={editOrder}
                  setupIntentClientSecret={setupIntentClientSecret}
                />
              </Elements>
            </div>
            <OrderModalFooter
              goBack={() => goToSecondStep(false)}
              submitButton
              onClose={onCloseOrderModal}
              onHold={
                hideOnHoldButton ? undefined : () => setShowOnHoldAlert(true)
              }
              isLoading={isLoading || stripeLoading}
            />
          </>
        );
      default:
        return null;
    }
  };

  const orderHeading = useMemo(() => {
    switch (currentStep) {
      case STEPS_COUNT.FIRST:
        return 'Event Information';

      case STEPS_COUNT.SECOND:
        return 'Product Selection';

      default:
        return 'Checkout';
    }
  }, [currentStep]);

  const onSubmitWithoutCC: SubmitHandler<CreateAVOrder> = useCallback(
    async (data) => {
      const apiPayload = apiPayloadCreator(data);

      if (setupIntentId && setupIntentClientSecret) {
        await cancelSetupIntent(setupIntentId);
      }

      if (editOrder?._id) {
        await updateOrder(apiPayload, editOrder?._id);
        return;
      }

      await addOrder(apiPayload);
    },
    [
      apiPayloadCreator,
      editOrder?._id,
      setupIntentId,
      setupIntentClientSecret,
      addOrder,
      cancelSetupIntent,
      updateOrder,
    ],
  );

  const handleSubmitOrderWithoutCC = async () => {
    const promiseMethod = handleSubmit(onSubmitWithoutCC);
    await promiseMethod();
  };

  return showSubmittedData ? (
    <OrderSubmissionSuccess
      eventProfileDynamicFields={eventProfileFields}
      contactDynamicFields={contactFields}
      eventDetailFormFields={eventDetailFields}
      endClients={endClients}
      bundles={bundles}
      items={items}
      states={states}
      getValues={getValues}
      goBack={closeOrderModal}
      editOrder={editOrder}
      getFieldState={getFieldState}
      latesSubmittedOrder={editOrder || latesSubmittedOrder}
      programManagers={programManagerData?.programManagers ?? []}
    />
  ) : (
    <Modal
      className='w-[85%] max-w-[1400px] h-[calc(100vh-80px)] mt-3'
      open={orderModal}
      onClose={onCloseOrderModal}
    >
      <h3 className='text-2xl font-bold mb-3'>{`${
        editOrder?._id ? 'Edit' : 'New'
      } Order: ${orderHeading}`}</h3>

      <form onSubmit={handleSubmit(onSubmit)} className='h-[calc(100%-28px)]'>
        <Steps steps={formSteps} />
        <div className='w-[100%] mx-auto overflow-y-auto h-[calc(100%-60px)] mt-5'>
          {renderStep()}
        </div>
      </form>

      <AlertMessage
        title='Hold Order'
        message={ORDER_ALERT_MESSAGES.ON_HOLD}
        buttons={[
          {
            label: 'Cancel',
            className:
              '!bg-white !border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowOnHoldAlert(false),
          },
          {
            label: 'Confirm',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: holdOrder,
          },
        ]}
        isOpen={showOnHoldAlert}
        onClose={() => setShowOnHoldAlert(false)}
      />
      <AlertMessage
        title='Error'
        message={ORDER_ALERT_MESSAGES.FORM_VALIDATION_ERROR}
        buttons={[
          {
            label: 'Ok',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowErrorAlert(false),
          },
        ]}
        isOpen={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
      />
      <AlertMessage
        title='Close Order'
        message={ORDER_ALERT_MESSAGES.CLOSE}
        buttons={[
          {
            label: 'Close',
            className: '!text-white !bg-red-500 mr-2 !px-12 rounded-xl',
            onClick: closeConfirmationModal,
          },
        ]}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      />
      <AlertMessage
        title='Card Error'
        message={showCardErrorAlertMessage}
        buttons={[
          {
            label: 'Submit Order',
            className:
              '!border-gray-300 !bg-white !text-gray-950 mr-2 !px-4 rounded-xl',
            onClick: () => {
              handleSubmitOrderWithoutCC();
            },
            isLoading: isLoading || stripeLoading,
          },
          {
            label: 'Retry',
            className: '!text-white !bg-green-950 mr-2 !px-4 rounded-xl',
            onClick: () => setShowCardErrorAlert(false),
          },
        ]}
        isOpen={showCardErrorAlert}
        onClose={() => setShowCardErrorAlert(false)}
      />
      <AlertMessage
        title='Warning'
        message={ORDER_ALERT_MESSAGES.RUSH_ORDER}
        buttons={[
          {
            label: 'Cancel',
            className:
              '!bg-white !border-gray-300 !text-gray-950 mr-2 !px-12 rounded-xl',
            onClick: () => setShowRushOrderAlert(false),
          },
          {
            label: 'Continue',
            className: '!text-white !bg-green-950 mr-2 !px-12 rounded-xl',
            onClick: () => {
              setShowRushOrderAlert(false);
              proceedToSecondStep();
            },
          },
        ]}
        isOpen={showRushOrderAlert}
        onClose={() => setShowRushOrderAlert(false)}
      />
    </Modal>
  );
};

export default OrderForm;
